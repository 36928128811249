.list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: var(--custom-color-card);
  border-radius: var(--custom-border-radius);

  &__subline {
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem 2.5rem 0;
  }

  h3 {
    margin-bottom: 1rem;
  }

  .entry {
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}

.entry {
  border-radius: var(--custom-border-radius);
  background: var(--custom-color-card);
  color: var(--custom-color-text);
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  span {
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }

  button {
    min-width: unset;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }

  &--spaced {
    justify-content: space-between;
  }
}