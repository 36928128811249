@import "src/style/utils/breakpoints";

.tabs {
  position: relative;
  width: 100%;
  background: var(--custom-color-card);
  padding: .5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: var(--custom-border-radius);
  margin-bottom: 1.5rem;

  &__el {
    display: flex;
    justify-content: flex-start;
    margin: .5rem 0;
    flex: 1;

    @include mq('tab') {
      flex: unset;
    }

    button {
      margin-right: .5rem;
      min-width: 6rem;
      flex: 1;

      @include mq('tab') {
        flex: unset;
      }
    }
  }
}