@import "src/style/utils/breakpoints";

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--custom-color-bg-accent);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include mq('tab') {
    padding: 1rem 2rem;
  }

  input {
    margin-right: 1rem;
    flex: 1;
  }
}