@import "src/style/utils/breakpoints";

.group-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.group-card {
  margin-bottom: 1.5rem;
}