$bp: (
        'mob-s': 375px,
        'mob-l': 439px,
        'tab': 769px,
        'dtp': 1025px,
        'dtp-wide': 1441px,
        'dtp-large': 1921px
);

@mixin mq($width) {
  @if map-has-key($bp, $width) {
    $width: map-get($bp, $width);
    @media only screen and (min-width: $width) {
      @content;
    }
  }
}