@import "src/style/utils/breakpoints";

button,
.button {
  border: var(--custom-border);
  background-color: var(--custom-color-bg);
  display: inline-block;
  text-align: center;
  border-radius: var(--custom-border-radius);
  padding: .5rem .75rem;
  min-height: 2.25rem;
  min-width: 2.25rem;
  cursor: pointer;
  text-align: center;
  font-size: .875rem;
  letter-spacing: .01em;
  width: auto;
  white-space: nowrap;
  font-weight: 500;

  img,
  svg {
    width: 1.125rem;
    height: auto;
  }
}

.button-primary {
  color: var(--custom-color-white);
  background-color: var(--custom-color-brand);
  border: transparent;
  transition: background-color .1s linear;

  &:hover {
    transition: background-color .1s linear;
    background-color: var(--custom-color-brand-hover);
  }
}

.button-secondary {
  color: var(--custom-color-text);
  background-color: var(--custom-color-bg-accent);
  border: var(--custom-border);
  transition: background-color .1s linear;

  &:hover {
    transition: background-color .1s linear;
    background-color: var(--custom-color-accent);
  }

  &--highlight {
    border: 1px solid var(--custom-color-text);
  }
}

.button-hidden {
  color: var(--custom-color-text);
  padding: unset;
  min-height: unset;
  background: unset;
  min-width: unset;
  border: none;
  cursor: pointer;
  font-size: .875rem;
  letter-spacing: .01em;
  white-space: nowrap;
  font-weight: 500;

  &:hover {
    color: var(--custom-color-placeholder);
  }
}

.button-wrapper {
  display: flex;
  padding: 1rem 0 .25rem;
  flex-wrap: wrap;

  button {
    margin-bottom: .75rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &--flex {
    gap: 0 .5rem;

    button {
      flex: 1;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

.button-toggle {
  position: relative;
  width: 3rem;
  height: 1.75rem;
  padding: 0;
  min-height: unset;
  border-radius: 2rem;
  border: none;
  background: var(--custom-color-bg-accent);

  @include mq('tab') {
    width: 3.5rem;
  }

  &__handle {
    position: absolute;
    top: .125rem;
    left: .125rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    outline: none;
    background: var(--custom-color-card-hover);

    &--active {
      transform: translateX(1.175rem);
      background: var(--custom-color-brand);

      @include mq('tab') {
        transform: translateX(1.675rem);
      }
    }
  }
}

.button-picture {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.tag-wrapper {
  margin: 1rem 0;

  .tag {
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.tag {
  display: inline-flex;
  align-items: center;
  text-align: center;
  border-radius: var(--custom-border-radius);
  border: var(--custom-border);
  padding: .25rem .5rem;
  min-height: 2.25rem;
  min-width: 2.25rem;
  /*cursor: pointer;*/
  text-align: center;
  font-size: .75rem;
  letter-spacing: .01em;
  width: auto;
  white-space: nowrap;
  font-weight: 500;
  color: var(--custom-color-text);
  transition: background-color .1s linear;
  gap: .375rem;

/*  &:hover {
    transition: background-color .1s linear;
    background-color: var(--custom-color-bg-accent);
  }*/

  span {
    transform: translateY(-.0875rem);
  }

  .icon-stroke {
    stroke: var(--custom-color-text);
  }
}