@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

p {
  margin-block-start: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-family: Inter,sans-serif;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -.04em;
  font-weight: 700;
}

h2 {
  font-family: Inter,sans-serif;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -.04em;
  font-weight: 500;
}

h3 {
  font-family: Inter,sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -.04em;
  font-weight: 500;
}

.label {
  font-size: .75rem;
  color: var(--custom-color-placeholder)
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

.p-sml {
  font-size: .875rem;
  line-height: 1.375rem;
}