/* Common Layout */
@import "src/style/layouts/common";

/* Common Type */
@import "src/style/utils/type";

/* Common Components */
@import "src/style/components/button";

/* Common Components */
@import "src/style/components/card-default";

body {
  --custom-color-bg: #eeedea;
  --custom-color-bg-accent: rgb(255,255,255);
  --custom-color-card: rgba(255, 255, 255, 0.4);
  --custom-color-card-hover: rgba(0, 0, 0, 0.08);
  --custom-color-text: #161616;
  --custom-color-text-invert: #161616;
  --custom-color-placeholder: rgba(0, 0, 0, 0.5);
  --custom-color-brand: #510dd9;
  --custom-color-brand-hover: #2e0581;
  --custom-color-accent: rgba(0, 0, 0, 0.04);
  --custom-box-shadow: 0 .125rem .5rem 0 rgba(0, 0, 0, 0.04), 0 .25rem 1rem 0 rgba(0, 0, 0, 0.08);
  --custom-color-white: #fff;
  --custom-color-black: #000;
}
body.dark-theme {
  --custom-color-bg: #16161D;
  --custom-color-bg-accent: rgb(0, 0, 0);
  --custom-color-card: rgba(255, 255, 255, 0.04);
  --custom-color-card-hover: rgba(255, 255, 255, 0.08);
  --custom-color-text: #fff;
  --custom-color-text-invert: #161616;
  --custom-color-placeholder: rgba(255, 255, 255, 0.5);
  --custom-color-brand: #510dd9;
  --custom-color-brand-hover: #2e0581;
  --custom-color-accent: rgba(255, 255, 255, 0.2);
  --custom-box-shadow: 0 .125rem .5rem 0 rgba(0, 0, 0, 0.04), 0 .25rem 1rem 0 rgba(0, 0, 0, 0.08);
  --custom-color-white: #fff;
  --custom-color-black: #000;
}

@media (prefers-color-scheme: dark) {
  body {
    --custom-color-bg: #16161D;
    --custom-color-bg-accent: rgb(0, 0, 0);
    --custom-color-card: rgba(255, 255, 255, 0.04);
    --custom-color-card-hover: rgba(255, 255, 255, 0.08);
    --custom-color-text: #fff;
    --custom-color-text-invert: #161616;
    --custom-color-placeholder: rgba(255, 255, 255, 0.5);
    --custom-color-brand: #510dd9;
    --custom-color-brand-hover: #2e0581;
    --custom-color-accent: rgba(255, 255, 255, 0.2);
    --custom-box-shadow: 0 .125rem .5rem 0 rgba(0, 0, 0, 0.04), 0 .25rem 1rem 0 rgba(0, 0, 0, 0.08);
    --custom-color-white: #fff;
    --custom-color-black: #000;
  }
  body.light-theme {
    --custom-color-bg: #eeedea;
    --custom-color-bg-accent: rgb(255,255,255);
    --custom-color-card: rgba(255, 255, 255, 0.4);
    --custom-color-card-hover: rgba(0, 0, 0, 0.08);
    --custom-color-text: #161616;
    --custom-color-text-invert: #161616;
    --custom-color-placeholder: rgba(0, 0, 0, 0.5);
    --custom-color-brand: #510dd9;
    --custom-color-brand-hover: #2e0581;
    --custom-color-accent: rgba(0, 0, 0, 0.04);
    --custom-box-shadow: 0 .125rem .5rem 0 rgba(0, 0, 0, 0.04), 0 .25rem 1rem 0 rgba(0, 0, 0, 0.08);
    --custom-color-white: #fff;
    --custom-color-black: #000;
  }
}

html,
body {
  --custom-font-family: 'Inter', sans-serif;
  --custom-border: 1px solid var(--custom-color-accent);
  --custom-border-radius: .25rem;
  --custom-spacing: .25rem;
  padding: 0;
  margin: 0;
  font-family: var(--custom-font-family);
  background-color: var(--custom-color-bg);
}

* {
  color: var(--custom-color-text);
  font-family: var(--custom-font-family);
  box-sizing: border-box;
  position: relative;
}

html,
body {
  min-height: 100vh;
  width: 100%;
}

/* Forms */

label {
  display: block;
  margin: 5px 0;
  color: var(--custom-color-text);
  font-size: 0.8rem;
  text-transform: uppercase;
}

input,
textarea {
  width: 100%;
  border-radius: 5px;
  border: var(--custom-border);
  padding: 8px;
  font-size: 0.9rem;
  background-color: var(--custom-color-card);
  color: var(--custom-color-text);

  &::placeholder {
    color: var(--custom-color-placeholder);
  }

  &:focus {
    outline: 1px solid var(--custom-color-placeholder);
  }
}

input[disabled] {
  color: var(--custom-color-placeholder);
  border: 1px solid transparent;
}

select {
  background: transparent;
}

/* Utils */

.block {
  display: block;
  width: 100%;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &--fill {
    flex: 1;
  }

  &--align-center {
    align-items: center;
  }

  &--spaced {
    justify-content: space-between;
  }

  &--baseline {
    align-items: baseline;
  }
}

.padding {
  padding: 1rem;
}
.flex.column {
  flex-direction: column;
}
.flex.row {
  flex-direction: row;
}
.flex.flex-1 {
  flex: 1 1 0;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}

.text-right {
  text-align: right;
}


/* Widgets */

.avatar {
  border-radius: var(--custom-border-radius);
  overflow: hidden;
  max-width: 100%;
}
.avatar.image {
  object-fit: cover;
}
.avatar.no-image {
  background-color: #333;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.avatarPlaceholder {
  border: var(--custom-border);
  border-radius: var(--custom-border-radius);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-widget {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-widget > .button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #444444;
  text-transform: none !important;
  transition: all 0.2s ease;
}

.form-widget .button:hover {
  background-color: #2a2a2a;
}

.form-widget .button > .loader {
  width: 17px;
  animation: spin 1s linear infinite;
  filter: invert(1);
}
/*
Forms*/

.checkbox {
  display: flex;
  padding: 1rem 0;
  cursor: pointer;
}

.checkbox > input {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.checkbox > label {
  cursor: pointer;
}

