.group-card {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  border-radius: var(--custom-border-radius);
  border: var(--custom-border);
  padding: 1.5rem 1rem;
  margin: 1rem 0;

  &:first-child {
    margin-top: 1.5rem;
  }

  &:last-child {
    margin-bottom: 3rem;
  }

  &:hover {
    background: var(--custom-color-card);
  }

  a {
    margin: 1rem 0;
    font-size: .875rem;
  }
}