@mixin card {
  width: 100%;
  display: block;
  border-radius: var(--custom-border-radius);
  background: var(--custom-color-card);
/*  box-shadow: var(--custom-box-shadow);*/
  border: var(--custom-border);
  padding: 1rem;
}

.card {
  @include card;

  .button-wrapper {
    padding-bottom: 0;
  }

  .list {
    margin-top: 1rem;
  }

  &__headline {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  &--rainbow {
    max-width: 30rem;
    border: 1px solid var(--custom-color-accent);
    box-shadow: none;
  }

  &--sticky {
    position: sticky;
    top: 5.5rem;
  }
}