.card-link {
  display: inline-flex;
  border-radius: var(--custom-border-radius);
  border: var(--custom-border);
  background: var(--custom-color-card);
  padding: 1rem;
  margin-bottom: 1rem;
  transition: background .1s linear;

  &:hover {
    background: var(--custom-color-card-hover);
    transition: background .1s linear;
  }
}