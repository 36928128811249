.video-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-radius: var(--custom-border-radius);
  border: var(--custom-border);
  padding: 1rem;
  transition: background-color .2s linear;

  &:hover {
    background: var(--custom-color-card);
    transition: background-color .2s linear;
  }

  &--flat {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    background: none;
    border: none;
  }

  a {
    margin: .5rem 0 0;
    font-size: .875rem;
  }

  &__spaced-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.video-responsive {
  width: 100%;
  overflow: hidden;
  padding-bottom: calc(100% / 16 * 9);
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.subrow {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: .75rem 0;
}

.rating-score {
  display: flex;
  flex-wrap: wrap;

  button {
    margin-bottom: .25rem;
    &:not(:last-child) {
      margin-right: .25rem;
    }
  }
}

