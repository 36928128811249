@import "src/style/utils/breakpoints";

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--custom-color-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  color: var(--custom-color-text);
  border-bottom: 1px solid var(--custom-color-accent);
  z-index: 2;

  @include mq('tab') {
    padding: 1rem 2rem;
  }

  a:not(:last-child) {
    margin-right: .75rem;

    @include mq('tab') {
      margin-right: 1rem;
    }
  }

  svg {
    width: 1.75rem;
    height: auto;
    transform: translateY(.125rem);
  }

  .icon-path {
    stroke: var(--custom-color-text);
  }

  .active {
    border: 1px solid var(--custom-color-text);
  }

  .button-secondary {
    height: 2.25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}