@import "src/style/utils/breakpoints";

.video-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .video-card {
    margin-bottom: 1.5rem;

    @include mq('tab') {
      width: calc(100% / 2 - 1.5rem * 1 / 2);
      margin-right: 1.5rem;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @include mq('dtp') {
      width: calc(100% / 3 - 1.5rem * 2 / 3);
      margin-right: 1.5rem;

      &:nth-child(2n + 2) {
        margin-right: 1.5rem;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
}