@import "src/style/utils/breakpoints";

.container {
  width: 100%;
  padding: 5.5rem 1rem;

  @include mq('tab') {
    padding: 5.5rem 2rem;
  }

  @include mq('dtp-large') {
    padding: 5.5rem 5% 4rem;
  }

  &--flex {
    display: flex;
    flex-wrap: wrap;
  }

  &--reverse {
    flex-direction: column-reverse;

    @include mq('dtp') {
      flex-direction: row;
    }
  }

  &--auth {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;

    @include mq('dtp') {
      padding: 5.5rem 25%;
      align-items: center;
    }
  }
}

.container-row {

  &--small {
    width: 100%;

    @include mq('dtp') {
      position: sticky;
      width: 30%;
      padding: 0 1rem;
    }
  }

  &--fill {
    width: 100%;
    order: 3;

    @include mq('dtp') {
      order: unset;
      flex: 1;
      padding: 0 1rem;
    }
  }
}

.logo-path {
  fill: var(--custom-color-text);
}