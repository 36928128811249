@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name)) + 1;
  } @else {
    @return null;
  }
}

$z-indexes: (
        'modal',
        'overlay',
        'nav',
        'action',
        'content',
        'bg'
);