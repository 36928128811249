@import "src/style/utils/breakpoints";
@import "src/style/utils/z-index";

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModalPortal {
  z-index: z('overlay');
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  justify-content: center;
  background: var(--custom-color-bg);
  z-index: z('overlay');

  @include mq('tab') {
    align-items: center;
    padding: 2rem;
  }
}

.modal {
  width: 100%;
  max-height: calc(100vh - 4rem);
  overflow-y: scroll;
  background: var(--custom-color-bg-accent);
  color: var(--custom-color-text);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  z-index: z('modal');

  @include mq('tab') {
    width: 80%;
  }

  @include mq('dtp') {
    width: 50%;
    max-width: 30rem;
  }

  h1, h2, h3 {
    margin-bottom: 1.5rem;
  }

  textarea, input {
    margin-bottom: 1rem;
  }
}